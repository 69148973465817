import {
  RequestBookmarksCommand,
  CheckRebootCommand,
  RequestInstalledAppsCommand,
  UpdateAppCommand,
} from 'kaistore-post-messenger/src/commands';
import { MessageSender } from 'web-message-helper';
import {
  kaistoreApps,
  mozAppEvent as MOZ_APP_EVENT,
} from 'kaistore-post-messenger/lib/constants';
import paymentHelper from './helper/payment-helper';
import {
  formatRemoteManifestURL,
  checkIsUpdatableApp,
  getManifestURLById,
  getQuerystring,
  isNextOS,
  isVersionHigher,
} from './utils';
import { CLIENT_LOWEST_REQUIREMENT, PATH, SPECIAL_CATE_CODE } from './constant';
import Application from './model/Application';
import AppListHelper from '@/helper/applist-helper';
import CacheHelper from '@/helper/cache-helper';
import GraphQLHelper from '@/helper/graphql-helper';
import routeHelper from '@/helper/route-helper';

const KEY_DOWNLOADED_APPS = 'downloaded-apps';
const DEFAULT_PAGE_SIZE = 20;
class AppStore {
  constructor() {
    this.init = () => {
      // Make KaiStore can go back APP detail page after user login.
      this.preparedDownloadApp = null;
      this.applications = new Map();

      this.kaipayUpdated = false;
      this.categories = {
        all: [],
        carrier: [],
        recommended: [],
        static: [],
      };
      this.storeUpdatable = false;
      this.updateAllInProcess = {
        system: false,
        remote: false,
      };
      this.bookmarksMap = new Map();
      this.appsByRanking = new Map();
      /*
      *  key: cateCode
      *  value: {
      *    pageNum: page last fetched,
      *    (pageSize): use APPS_PER_PAGE; won't store in the Map;
      *    isLastPage: true if no more apps to fetch on that cate,
      *  }
      * special key 'SPECIAL_CATE_CODE.ALL' for getting all apps
      * usage: fallback to get all apps for handling failed GraphQL request for inline activity
      */
      this.catePagination = new Map();
      CacheHelper.prepareCachedItem();
    };

    this.init();
  }

  get apps() {
    return [...this.applications.values()];
  }

  get allRemoteItems() {
    return this.apps.filter(
      app => app.isInRemoteList && !app.isInternal && !app.isPreloaded
    );
  }

  get bookmarks() {
    return this.apps.filter(app => app.isBookmark);
  }

  get installedApps() {
    return this.apps.filter(app => app.isInstalled);
  }

  get hasUpdatableApps() {
    return (
      this.updatableApps.system.length + this.updatableApps.remote.length > 0
    );
  }

  get updateAllRebootNeeded() {
    return this.updatableApps.system.some(app => app.isRebootNeeded);
  }

  get isUpdateAllInProcess() {
    const hasUpdatableSystemAppDownloading = this.updatableApps.system.some(
      app => app.state.downloading
    );
    const hasUpdatableRemoteAppDownloading = this.updatableApps.remote.some(
      app => app.state.downloading
    );
    return {
      system:
        !!this.nextUpdatePendingSystemApp || hasUpdatableSystemAppDownloading,
      remote:
        !!this.nextUpdatePendingRemoteApp || hasUpdatableRemoteAppDownloading,
    };
  }

  get nextUpdatePendingSystemApp() {
    const application = this.updatableApps.system.find(
      app => app.state.updatePending
    );
    return application;
  }

  get nextUpdatePendingRemoteApp() {
    const application = this.updatableApps.remote.find(
      app => app.state.updatePending
    );
    return application;
  }

  get updatableApps() {
    const updatableApps = this.apps.filter(
      app => app.state.updatable === true && !app.isInternal && !app.isPreloaded
    );
    return {
      system: updatableApps.filter(app => app.core),
      remote: updatableApps.filter(app => !app.core),
    };
  }

  get purchasedApps() {
    return this.allRemoteItems.filter(app => app.isPurchased === true);
  }

  async downloadedOrPurchasedApps() {
    const downloadRecord = await this._getDownloadAppRecord();

    if (downloadRecord && this.purchasedApps) {
      const purchasedRecord = this._getPurchasedAppsInfo();

      // remove duplicate.
      downloadRecord.forEach((app, id) => {
        if (purchasedRecord.has(id)) {
          purchasedRecord.delete(id);
        }
      });

      return this._sortDownloadRecordByDateTime([
        ...downloadRecord.values(),
        ...purchasedRecord.values(),
      ]);
    }

    if (this.purchasedApps) {
      return this._sortDownloadRecordByDateTime([
        ...this._getPurchasedAppsInfo().values(),
      ]);
    }

    if (downloadRecord) {
      return this._sortDownloadRecordByDateTime([...downloadRecord.values()]);
    }

    return [];
  }

  _sortDownloadRecordByDateTime(record = []) {
    record.sort((a, b) => b.downloadedDate - a.downloadedDate);
    return record;
  }

  _getPurchasedAppsInfo() {
    if (this.purchasedApps.length === 0) {
      return new Map();
    }
    return new Map(
      this.purchasedApps.map(app => [
        app.id,
        {
          manifestURL: app.manifestURL,
          downloadedDate: app.purchasedDate,
          ...app.brickInfo,
        },
      ])
    );
  }

  _getDownloadAppRecord() {
    return new Promise(resolve => {
      asyncStorage.getItem(KEY_DOWNLOADED_APPS, result => {
        const record = result || new Map();
        resolve(record);
      });
    });
  }

  addDownloadAppRecord(info) {
    this._getDownloadAppRecord().then(result => {
      const record = !!result && !!result.set ? result : new Map();
      record.set(info.id, info);
      asyncStorage.setItem(KEY_DOWNLOADED_APPS, record);
    });
  }

  resetStore() {
    this.init();
  }

  initHelpers() {
    this.appListHelper = new AppListHelper();
    this.graphQLHelper = new GraphQLHelper();
  }

  async initPurchasedApps() {
    return paymentHelper.getAllPurchased().then((products = []) => {
      products
        .filter(product => product.type === 1 && product.related_good_id)
        .forEach(product => {
          const id = product.related_good_id;
          const manifestURL = getManifestURLById(id);
          const existedApplication = this.findAppByManifest(manifestURL);
          const application =
            existedApplication || new Application(manifestURL);
          application.isPurchased = true;
          application.purchasedDate = product.created_date;
          this.applications.set(manifestURL, application);
        });
      this.publish('appstore:change');
    });
  }

  fetchComboList(hasToken) {
    let promise = null;
    if (CacheHelper.isCacheValid) {
      const { categories, comboApps } = CacheHelper.item;
      promise = Promise.resolve({ categories, comboApps, isFromCache: true });
    } else if (hasToken) {
      promise = this.appListHelper.fetchComboList();
    } else {
      console.error('No restricted token for fetching combo list.');
      return Promise.reject();
    }

    return promise.then(result => {
      performance.mark('FETCH_APPS_CAT_END');
      const { categories, comboApps, isFromCache } = result;
      if (categories.all.length === 0 && comboApps.length === 0) {
        throw {
          errorMsgId: 'no-app-available',
        };
      }
      // special handle recommended apps so they can be treated like apps from static cate
      this.setPaginationByCate(SPECIAL_CATE_CODE.RECOMMENDED, {
        isLastPage: true,
      });
      const recommendedApps = comboApps
        .filter(app => Boolean(app.recommended_index && app.manifest_url))
        .sort((app1, app2) => app1.recommended_index - app2.recommended_index)
        .map(app => formatRemoteManifestURL(app.manifest_url));
      this.appsByRanking.set(
        SPECIAL_CATE_CODE.RECOMMENDED,
        new Set(recommendedApps)
      );

      if (!isFromCache) {
        CacheHelper.setValidCacheItem(categories, comboApps);
      }
      this.categories = {
        ...this.categories,
        ...categories,
      };
      this.initComboApps(comboApps);
      return result;
    });
  }

  getAppsByCate(cateCode) {
    const { isLastPage } = this.getPaginationByCate(cateCode);
    // If can get apps order from appList API then sync order with it, else keep origin order.
    return this.appsByRanking.get(cateCode)
      ? this.formatAppsByApiRanking(cateCode, isLastPage)
      : {
          apps: this.allRemoteItems.filter(
            app => app.remoteInfo.category === cateCode
          ),
          isLastPage: true,
        };
  }

  getPaginationByCate(cateCode, shouldFetchAll) {
    const allCatePagination = this.catePagination.get(SPECIAL_CATE_CODE.ALL);
    if (allCatePagination) {
      // No need to fetch if all apps have been fetched
      return allCatePagination;
    }
    const pagination = this.catePagination.get(cateCode);
    if (!pagination) {
      return cateCode === SPECIAL_CATE_CODE.ALL || shouldFetchAll
        ? {
            // omit pageNum and pageSize here to get all apps
            isLastPage: false,
          }
        : {
            pageNum: 1,
            pageSize: DEFAULT_PAGE_SIZE,
            isLastPage: false,
          };
    }
    return pagination.isLastPage
      ? // do not alter the pagination object if it has reached the last page of the category
        pagination
      : {
          ...pagination,
          pageNum: pagination.pageNum + 1,
          pageSize: DEFAULT_PAGE_SIZE,
        };
  }

  setPaginationByCate(cateCode, pagination) {
    this.catePagination.set(cateCode, pagination);
  }

  isCarrierCate(cateCode) {
    return this.categories.carrier.map(cate => cate.code).includes(cateCode);
  }

  fetchGraphQL(graphQLQuery, shouldReturnYMAL = false) {
    return this.graphQLHelper
      .fetchApp(graphQLQuery, shouldReturnYMAL)
      .then(app => {
        if (!app) throw new Error('App not found through graphQL');
        this.handleFormatGraphQLApp(app);
        return app;
      });
  }

  // if graphQLApp contains YMAL apps, create/update Application for them first
  handleFormatGraphQLApp(graphQLApp) {
    const rawYMALApps = graphQLApp.ymal;
    const sponsoredYMALs = [];
    const ymalApps = [];

    if (rawYMALApps) {
      rawYMALApps.forEach(ymalApp => {
        if (ymalApp.is_sponsored) {
          // recommendation_id is presenting as ad_id if it's a sponsored_app.
          ymalApp.ad_id = ymalApp.recommendation_id;
          sponsoredYMALs.push(ymalApp);
        } else {
          ymalApps.push(ymalApp);
        }
      });

      if (sponsoredYMALs.length > 0) {
        // Randomly display one of sponsoredYMAL apps.
        ymalApps.unshift(
          sponsoredYMALs[Math.floor(Math.random() * sponsoredYMALs.length)]
        );
        graphQLApp.ymal = ymalApps;
      }
      ymalApps.forEach(ymalApp => this.formatGraphQLApp(ymalApp));
    }
    this.formatGraphQLApp(graphQLApp);
  }

  formatGraphQLApp(graphQLApp) {
    if (!graphQLApp.manifest_url) {
      console.warn(
        `Malformed app ${graphQLApp.name} without manifest_url. Skip.`
      );
      return;
    }
    const manifestURL = formatRemoteManifestURL(graphQLApp.manifest_url);
    const existedApplication = this.findAppByManifest(manifestURL);
    const application = existedApplication || new Application(manifestURL);

    if (application.setRemoteInfo(graphQLApp)) {
      if (application.rawYmal) {
        // According to https://bugzilla.kaiostech.com/show_bug.cgi?id=109053#c9
        // We select 3 uninstalled YMAL apps, and will not change the list for the same store session
        const selectedYmalInfo = application.rawYmal
          .map(ymalApp => [ymalApp.manifest_url, ymalApp.recommendation_id])
          .filter(ymalArray => {
            const manifest = ymalArray[0];
            const ymalApplication = this.findAppByManifest(manifest);
            return ymalApplication && !ymalApplication.isInstalled;
          })
          .slice(0, 3)
          .reduce((acc, [manifest, recommendationId]) => {
            acc[manifest] = recommendationId;
            return acc;
          }, {});
        // The shape of application.ymal is like: { [manifestURL]: recommendationId, ...}
        application.ymal = selectedYmalInfo;
        delete application.rawYmal;
      }
      this.applications.set(manifestURL, application);
    } else {
      console.warn('malformed app information for', graphQLApp);
    }
  }

  formatAppsByApiRanking(cateCode, isLastPage) {
    const ranking = this.appsByRanking.get(cateCode);
    if (!ranking) {
      return {
        apps: [],
        isLastPage: true,
      };
    }
    return {
      apps: Array.from(this.appsByRanking.get(cateCode)).reduce(
        (arr, manifestURL) => {
          const app = this.applications.get(manifestURL);
          if (!app) {
            return arr;
          }
          const isRemoteItems =
            app.isInRemoteList && !app.isInternal && !app.isPreloaded;
          const isCorrectCate =
            app.remoteInfo.category === cateCode ||
            // Will only need to check if recommended_index exists for SPECIAL_CATE_CODE.RECOMMENDED
            app.remoteInfo.recommended_index !== undefined;
          if (isRemoteItems && isCorrectCate) {
            arr.push(app);
          }
          return arr;
        },
        []
      ),
      isLastPage,
    };
  }

  fetchAppListByCate(cateCode, shouldFetchAll) {
    const {
      isLastPage: wasLastPage,
      pageNum,
      pageSize,
    } = this.getPaginationByCate(cateCode, shouldFetchAll);

    if (!wasLastPage) {
      return this.appListHelper
        .fetchAppListByCate({ cateCode, pageNum, pageSize })
        .then(({ apps, isLastPage }) => {
          const orderedAppsWithCate = apps
            // FIXME: quick fix to filter out malformed response,
            // should integrate with application.isRemoteInfoValid
            .filter(app => Boolean(app.manifest_url))
            .map(app => [
              formatRemoteManifestURL(app.manifest_url),
              app.category,
            ]);
          const appsByCateMap = orderedAppsWithCate.reduce(
            (acc, [manifestURL, cate]) => {
              const appArray = acc.get(cate);
              if (appArray) {
                appArray.push(manifestURL);
                acc.set(cate, appArray);
              } else {
                acc.set(cate, [manifestURL]);
              }
              return acc;
            },
            new Map()
          );

          // Store all fetched apps by their category into this.appsByRanking
          [...appsByCateMap].forEach(([cate, rankedApps]) => {
            const rankingList = this.appsByRanking.get(cate);
            if (!rankingList) {
              this.appsByRanking.set(cate, new Set(rankedApps));
            } else {
              this.appsByRanking.set(
                cate,
                new Set(Array.from(rankingList).concat(rankedApps))
              );
            }
          });

          // store all apps of special key (SPECIAL_CATE_CODE.ALL) into this.appsByRanking
          if (cateCode === SPECIAL_CATE_CODE.ALL) {
            this.appsByRanking.set(
              cateCode,
              new Set(orderedAppsWithCate.map(appRank => appRank[0]))
            );
          }

          this.initRemoteApps(apps);
          this.setPaginationByCate(cateCode, { pageNum, isLastPage });
          this.publish('appstore:change');

          return this.formatAppsByApiRanking(cateCode, isLastPage);
        });
    }

    return Promise.resolve(this.formatAppsByApiRanking(cateCode, wasLastPage));
  }

  // FIXME: keep initComboApps and initRemoteApps for now; combine later.
  initComboApps(apps) {
    this.formatRemoteApps(apps);
  }

  initRemoteApps(remoteApps) {
    this.formatRemoteApps(remoteApps);
  }

  formatRemoteApps(remoteApps) {
    remoteApps.forEach(app => {
      if (!app.manifest_url) {
        console.warn(`Malformed app ${app.name} without manifest_url. Skip.`);
        return;
      }
      const manifestURL = formatRemoteManifestURL(app.manifest_url);
      const existedApplication = this.findAppByManifest(manifestURL);
      const application = existedApplication || new Application(manifestURL);

      if (application.setRemoteInfo(app)) {
        if (
          application.isBookmark &&
          this.bookmarksMap.has(application.info.url)
        ) {
          application.state.pinned = true;
        }

        if (!existedApplication) {
          this.applications.set(manifestURL, application);
        }
      } else {
        console.warn('malformed app information for', app);
      }
    });
  }

  syncInstalledApps() {
    return new Promise((resolve, reject) => {
      performance.mark('REQUEST_INSTALLED_START');
      MessageSender.send(
        new RequestInstalledAppsCommand(),
        (success, detail) => {
          performance.mark('REQUEST_INSTALLED_END');
          if (success) {
            resolve(detail);
          }
          reject(new Error('Failed to get installed apps from client'));
        }
      );
    }).then(installedAppsObj => {
      Object.values(installedAppsObj).forEach(mozAPP => {
        const manifestURL = this._getCoreManifestURL(mozAPP);

        const existedApplication = this.findAppByManifest(manifestURL);
        const application = existedApplication || new Application(manifestURL);

        application.mozAPP = mozAPP;
        this.syncUpdatableApp(application);

        if (this.isKaiStore(application.id)) {
          this.updateClient(manifestURL);
        }

        this.applications.set(manifestURL, application);
      });
    });
  }

  updateClient = manifestURL => {
    const osFamily = isNextOS() ? 'KAIOS_3.0' : 'KAIOS_2.5';
    const querystring = getQuerystring(routeHelper.entryURL);
    const searchParams = new URLSearchParams(querystring);
    const version = searchParams.get('version');

    if (!isVersionHigher(CLIENT_LOWEST_REQUIREMENT[osFamily], version)) {
      console.warn(
        `[Client Force Update] Current store version is ` +
          `${version} <= ${CLIENT_LOWEST_REQUIREMENT[osFamily]}.`
      );
      MessageSender.send(
        new UpdateAppCommand({
          detail: { manifestURL },
        })
      );
    }
  };

  syncUpdatableApp(application) {
    if (checkIsUpdatableApp(application)) {
      application.updateState({ updatable: true });
    }
  }

  initBookmarksMap() {
    return new Promise(resolve => {
      MessageSender.send(new RequestBookmarksCommand(), (success, detail) => {
        if (success) {
          this.bookmarksMap = new Map(Object.entries(detail));
          resolve({ isBookmarkDBSupported: true });
        } else {
          resolve({ isBookmarkDBSupported: false });
        }
      });
    });
  }

  isKaiStore(appId) {
    return Object.values(kaistoreApps).includes(appId);
  }

  updateAll(type, enable = true) {
    const apps = this.updatableApps[type];
    if (apps && apps.length > 0) {
      apps.forEach(app => {
        /**
         * XXX: not to cancelDownload() for now because it may trigger Gecko
         * error on mozapp's state sticking at 'pending'
         */
        // if (!enable && app.state.downloading) {
        //   app.cancelDownload();
        // }
        app.updateState({ updatePending: enable });
      });
    }
    if (!enable) {
      return;
    }
    if (type === 'system' && this.updatableApps.system.length > 0) {
      const nextApplication = this.nextUpdatePendingSystemApp;
      if (nextApplication) {
        routeHelper.route(
          PATH.CORE_PAGE.URL({
            manifest: nextApplication.manifestURL,
            batchUpdating: true,
          })
        );
      }
    } else if (type === 'remote' && this.updatableApps.remote.length > 0) {
      const nextApplication = this.nextUpdatePendingRemoteApp;
      if (nextApplication) {
        nextApplication.update(true);
      }
    }
  }

  handleUpdate(manifestURL, mozAppEvent) {
    switch (mozAppEvent) {
      case MOZ_APP_EVENT.ON_DOWNLOAD_APPLIED:
      case MOZ_APP_EVENT.ON_DOWNLOAD_SUCCESS:
      case MOZ_APP_EVENT.ON_DOWNLOAD_ERROR:
        if (this.isUpdateAllInProcess.remote) {
          const nextApplication = this.updatableApps.remote.find(
            app => app.state.updatePending
          );
          if (nextApplication) {
            nextApplication.update();
          }
        }
        // stop the core update all process if download failed
        if (
          this.isUpdateAllInProcess.system &&
          mozAppEvent === MOZ_APP_EVENT.ON_DOWNLOAD_ERROR
        ) {
          const application = this.findAppByManifest(manifestURL);
          if (application.core) {
            this.updatableApps.system.forEach(app => {
              app.updateState({ updatePending: false });
            });
          }
        }
        break;
      default:
        break;
    }
  }

  findAppByName(name) {
    return this.apps.find(app => app.name === name);
  }

  findItemById(id) {
    return this.allRemoteItems.find(item => item.id === id);
  }

  findAppByManifest(manifestURL) {
    return this.applications.get(manifestURL);
  }

  findBookmarkByUrl(url) {
    return this.bookmarks.find(bookmark => bookmark.info.url === url);
  }

  findAppForPage(matches) {
    const { id, manifest, name } = matches;
    if (id && id !== '') {
      return this.findItemById(id);
    }
    if (manifest) {
      const manifestURL = decodeURIComponent(manifest);
      return this.findAppByManifest(manifestURL);
    }
    if (name) {
      return this.findAppByName(name);
    }
    return null;
  }

  _getCoreManifestURL(mozAPP) {
    const hasUpdateURL = mozAPP.manifest && mozAPP.manifest.updateURL;
    return hasUpdateURL ? mozAPP.manifest.updateURL : mozAPP.manifestURL;
  }

  publish(eventName) {
    const evt = new CustomEvent(eventName);
    window.dispatchEvent(evt);
  }

  rebootIfNeeded() {
    MessageSender.send(new CheckRebootCommand());
  }

  clearEmptyApplication(manifestURL) {
    this.applications.delete(manifestURL);
  }

  fetchGraphQLForActivity(graphQLQuery) {
    return this.fetchGraphQL(graphQLQuery).then(app => {
      const manifestURL = app.manifest_url;
      const application = this.findAppByManifest(manifestURL);
      if (!application.remoteInfo.paid || application.isPurchased) {
        return app;
      }
      /**
       * If the paid app is not purchased, we would need to show price and
       * currency(optional) on the detail page and price field is mandatory to
       * proceed payment process. And these information are not available from
       * graphQL response, so we need to fetch by category.
       */
      if (app.category_list && app.category_list[0]) {
        const category = app.category_list[0];
        return this.fetchAppListByCate(category, true);
      }
      throw `missing category_list[0] to fetchGraphQLForActivity`;
    });
  }
}

export default new AppStore();
